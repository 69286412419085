import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const HandleDeleteMenuItem = ({ item, onClose, onDeleteConfirm }) => {
  const handleConfirmDelete = () => {
    const storedMenuData = ls.get("localDB_menuItems") || {
      menu: [],
      deleted: [],
    };

    // Remove the item from the menu
    const updatedMenuItems = storedMenuData.menu.filter(
      (menuItem) => menuItem.name !== item.name
    );

    // Add to the deleted list
    const updatedDeletedItems = [...(storedMenuData.deleted || []), item];

    // Update local storage
    storedMenuData.menu = updatedMenuItems;
    storedMenuData.deleted = updatedDeletedItems;
    ls.set("localDB_menuItems", storedMenuData);

    // Create a log entry for deletion
    const newLog = {
      name: item.name,
      dateModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      action: "Deleted",
    };
    storedMenuData.history = [...(storedMenuData.history || []), newLog];
    ls.set("localDB_menuItems", storedMenuData);

    onDeleteConfirm();
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Delete Menu Item</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to permanently delete{" "}
          <strong>{item.name}</strong>? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleDeleteMenuItem;
