import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";

// Dialogs
import AddInventoryDialog from "./Dialogs/AddInventoryDialog";
import EditInventoryDialog from "./Dialogs/EditInventoryDialog";

// Functions
import HandleArchiveItem from "./Functions/HandleArchiveItem";
import HandleDeleteItem from "./Functions/HandleDeleteItem";
import InventoryHero from "./InventoryHero";

const ls = new SecureLS({ encodingType: "aes" });

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const defaultCurrencySymbol = "₱";
  const [currencySymbol, setCurrencySymbol] = useState(defaultCurrencySymbol);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedInventory = ls.get("localDB_inventory") || {
      categories: [],
      items: [],
    };
    setInventory(storedInventory.items);
  }, []);

  const generateRandomId = () => {
    const randomLetters = Math.random()
      .toString(36)
      .substring(2, 7)
      .toUpperCase();
    const dateTime = dayjs().format("YYYYMMDDHHmmss");
    return `${randomLetters}${dateTime}`;
  };

  const handleAddInventory = (newItem) => {
    const newItemWithId = {
      ...newItem,
      id: generateRandomId(), 
    };

    const updatedInventory = [...inventory, newItemWithId];
    setInventory(updatedInventory);

    const storedInventory = ls.get("localDB_inventory") || {
      categories: [],
      items: [],
    };
    storedInventory.items = updatedInventory;
    ls.set("localDB_inventory", storedInventory);
  };

  const openDeleteDialog = (item) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const openArchiveDialog = (item) => {
    setItemToArchive(item);
    setArchiveDialogOpen(true);
  };

  const openEditDialog = (item) => {
    setItemToEdit(item);
    setEditDialogOpen(true);
  };

  const getItemBackgroundColor = (expiryDate) => {
    if (!expiryDate) return "#ffffff";

    const today = dayjs().startOf("day");
    const expiry = dayjs(expiryDate).startOf("day");
    const diffDays = expiry.diff(today, "day");

    if (diffDays >= 5) return "#90EE90";
    if (diffDays >= 2 && diffDays <= 4) return "#FFFF99";
    if (diffDays >= 0 && diffDays <= 1) return "#FF7F7F";
    if (diffDays < 0) return "#D3D3D3";

    return "#ffffff";
  };

  const groupedInventory = inventory.reduce((acc, item) => {
    const expiryDate = dayjs(item.expiryDate).startOf("day");
    const today = dayjs().startOf("day");

    if (expiryDate.isBefore(today)) {
      acc["Expired"] = acc["Expired"] || [];
      acc["Expired"].push(item);
    } else {
      acc[item.category] = acc[item.category] || [];
      acc[item.category].push(item);
    }

    return acc;
  }, {});

  const sortedCategories = Object.keys(groupedInventory).sort((a, b) => {
    if (a === "Expired") return -1;
    if (b === "Expired") return 1;
    return 0;
  });

  const filteredInventory = sortedCategories.filter((category) => {
    return (
      category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      groupedInventory[category].some((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 4,
          gap: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add Inventory
        </Button>
      </Box>

      {filteredInventory.length > 0 ? (
        filteredInventory.map((category, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom>
              {category}
            </Typography>
            <Grid container spacing={2}>
              {groupedInventory[category].map((item, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <Box
                    sx={{
                      padding: 2,
                      border: "1px solid #ccc",
                      borderRadius: 2,
                      backgroundColor: getItemBackgroundColor(item.expiryDate),
                      position: "relative",
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "transform 0.2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() => openEditDialog(item)}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteDialog(item);
                      }}
                      color="error"
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        openArchiveDialog(item);
                      }}
                      color="secondary"
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 8,
                        left: 8,
                      }}
                    >
                      <ArchiveIcon />
                    </IconButton>

                    <Typography variant="h6">{item.name}</Typography>
                    <Typography>
                      Amount Ordered: {item.amountOrdered}
                    </Typography>
                    <Typography>
                      Current Amount: {item.currentAmount}
                    </Typography>
                    <Typography>
                      Price: {currencySymbol}
                      {item.price}
                    </Typography>
                    <Typography>Delivery Date: {item.deliveryDate}</Typography>
                    <Typography>Expiry Date: {item.expiryDate}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      ) : (
        <Typography align="center">No inventory found</Typography>
      )}

      <AddInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAdd={handleAddInventory}
      />

      {/* Delete Dialog */}
      {deleteDialogOpen && itemToDelete && (
        <HandleDeleteItem
          item={itemToDelete}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleteConfirm={() => {
            const updatedInventory = inventory.filter(
              (inv) => inv !== itemToDelete
            );
            setInventory(updatedInventory);

            // Update local storage to remove the deleted item from inventory
            const storedInventory = ls.get("localDB_inventory") || {
              items: [],
            };
            storedInventory.items = updatedInventory;
            ls.set("localDB_inventory", storedInventory);

            // Remove the deleted item from localDB_expense using multiple fields to ensure uniqueness
            const storedExpenses = ls.get("localDB_expense")?.expenses || [];
            console.log("storedExpenses", storedExpenses);
            const updatedExpenses = storedExpenses.filter(
              (expense) =>
                !(
                  expense.name === itemToDelete.name &&
                  expense.category === itemToDelete.category &&
                  expense.price === itemToDelete.price
                )
            );
            ls.set("localDB_expense", { expenses: updatedExpenses });

            // Close the dialog
            setDeleteDialogOpen(false);
          }}
        />
      )}

      {archiveDialogOpen && itemToArchive && (
        <HandleArchiveItem
          item={itemToArchive}
          onClose={() => setArchiveDialogOpen(false)}
          onArchiveConfirm={() => {
            const updatedInventory = inventory.filter(
              (inv) => inv !== itemToArchive
            );
            setInventory(updatedInventory);

            const storedInventory = ls.get("localDB_inventory") || {
              items: [],
            };
            storedInventory.items = updatedInventory;
            ls.set("localDB_inventory", storedInventory);

            setArchiveDialogOpen(false);
          }}
        />
      )}

      <EditInventoryDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onEdit={(updatedItem) => {
          const updatedInventory = inventory.map((invItem) =>
            invItem === itemToEdit ? updatedItem : invItem
          );
          setInventory(updatedInventory);
          const storedInventory = ls.get("localDB_inventory") || {
            categories: [],
            items: [],
          };
          storedInventory.items = updatedInventory;
          ls.set("localDB_inventory", storedInventory);
        }}
        item={itemToEdit}
      />
    </Box>
  );
};

export default Inventory;
