import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CssBaseline,
} from "@mui/material";

const ls = new SecureLS({ encodingType: "aes" });

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate(); 

  const generateUserId = () => {
    return Math.random().toString(36).substr(2, 8);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter a valid email.");
      return;
    }

    const userId = generateUserId();
    const userData = { email, userId };
    const existingData = ls.get("localDB") || [];

    existingData.push(userData);

    ls.set("localDB", existingData);

    // alert(`Login successful! Your userId is: ${userId}`);

    navigate("/demo/dashboard");

    setEmail("");
    setPassword("");
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#f0f0f0",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: "primary.main" }}
          >
            POS Login
          </Typography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Just for fun!"
              variant="outlined"
              autoComplete="current-password"
            />
            <Button
              type="submit" 
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Login;
