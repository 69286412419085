import React from "react";
import { TableRow, TableCell } from "@mui/material";

const TotalSummary = ({ totalRevenue, totalExpense }) => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={2}>Total Revenue</TableCell>
        <TableCell>
          {parseFloat(totalRevenue).toFixed(2).toLocaleString()}
        </TableCell>{" "}
        <TableCell colSpan={2}></TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>Total Expense</TableCell>
        <TableCell>
          {parseFloat(totalExpense).toFixed(2).toLocaleString()}
        </TableCell>{" "}
        <TableCell colSpan={2}></TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <strong>Net Total</strong>
        </TableCell>
        <TableCell
          sx={{
            color: totalRevenue + totalExpense > 0 ? "green" : "red",
            fontWeight: "bold",
          }}
        >
          {(parseFloat(totalRevenue) + parseFloat(totalExpense))
            .toFixed(2)
            .toLocaleString()}{" "}
        </TableCell>
        <TableCell colSpan={2}></TableCell>
      </TableRow>
    </>
  );
};

export default TotalSummary;
