import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const HandleDeleteItem = ({ item, onClose, onDeleteConfirm }) => {
  const handleConfirmDelete = () => {
    const storedInventory = ls.get("localDB_inventory") || { items: [] };
    const updatedInventory = storedInventory.items.filter(
      (inventoryItem) => inventoryItem !== item
    );

    // Save the updated inventory without the deleted item
    storedInventory.items = updatedInventory;
    ls.set("localDB_inventory", storedInventory);

    // Log the delete action (optional)
    const newLog = {
      name: item.name,
      deliveryDate: item.deliveryDate,
      expiryDate: item.expiryDate,
      dateModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      action: "Deleted",
    };
    storedInventory.history = [...(storedInventory.history || []), newLog];
    ls.set("localDB_inventory", storedInventory);

    onDeleteConfirm(); // Trigger the onDeleteConfirm callback
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Delete Inventory Item</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete <strong>{item.name}</strong>? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleDeleteItem;
