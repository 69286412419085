import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const EditInventoryDialog = ({ open, onClose, onEdit, item, setHistory }) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [amountOrdered, setAmountOrdered] = useState("");
  const [currentAmount, setCurrentAmount] = useState(""); // Non-editable
  const [price, setPrice] = useState(""); // New Price field
  const [deliveryDate, setDeliveryDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  useEffect(() => {
    const storedInventory = ls.get("localDB_inventory") || {
      categories: [],
      items: [],
    };
    setCategories(storedInventory.categories);
    setInventory(storedInventory.items);

    if (item) {
      setName(item.name);
      setCategory(item.category);
      setAmountOrdered(item.amountOrdered);
      setCurrentAmount(item.currentAmount); // Non-editable
      setPrice(item.price); // Set price field
      setDeliveryDate(item.deliveryDate);
      setExpiryDate(item.expiryDate);
    }
  }, [item]);

  const handleAddCategory = (e) => {
    if (e.key === "Enter" && newCategory) {
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);
      setCategory(newCategory);
      setNewCategory("");
      setIsAddingCategory(false);

      const storedInventory = ls.get("localDB_inventory") || {
        categories: [],
        items: [],
      };
      storedInventory.categories = updatedCategories;
      ls.set("localDB_inventory", storedInventory);
    }
  };

  const handleDeleteCategory = (cat) => {
    const hasAssignedItems = inventory.some(
      (invItem) => invItem.category === cat
    );

    if (hasAssignedItems) {
      alert("Category has items assigned to it.");
    } else {
      const updatedCategories = categories.filter(
        (category) => category !== cat
      );
      setCategories(updatedCategories);

      const storedInventory = ls.get("localDB_inventory") || {
        categories: [],
        items: [],
      };
      storedInventory.categories = updatedCategories;
      ls.set("localDB_inventory", storedInventory);
    }
  };

  const handleEdit = () => {
    let changes = [];

    if (item.name !== name) {
      changes.push(`Name: ${item.name} -> ${name}`);
    }
    if (item.amountOrdered !== amountOrdered) {
      changes.push(`Amount Ordered: ${item.amountOrdered} -> ${amountOrdered}`);
    }
    if (item.price !== price) {
      changes.push(`Price: ${item.price} -> ${price}`);
    }
    if (item.deliveryDate !== deliveryDate) {
      changes.push(`Delivery Date: ${item.deliveryDate} -> ${deliveryDate}`);
    }
    if (item.expiryDate !== expiryDate) {
      changes.push(`Expiry Date: ${item.expiryDate} -> ${expiryDate}`);
    }

    const updatedItem = {
      ...item,
      name,
      category,
      amountOrdered,
      currentAmount: item.currentAmount, // Keep currentAmount as is, non-editable
      price,
      deliveryDate,
      expiryDate,
    };

    onEdit(updatedItem);

    const storedInventory = ls.get("localDB_inventory") || {
      history: [],
      items: [],
    };

    if (changes.length > 0) {
      const newLog = {
        name: updatedItem.name,
        changes: changes.join(", "),
        dateModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        action: "Edited",
      };

      storedInventory.history = [...(storedInventory.history || []), newLog];
      ls.set("localDB_inventory", storedInventory);

      setHistory((prevHistory) => [...prevHistory, newLog]);
    }

    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
    setAmountOrdered("");
    setPrice("");
    setDeliveryDate("");
    setExpiryDate("");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => selected}
          >
            {categories.map((cat, index) => (
              <MenuItem
                key={index}
                value={cat}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat}
                <Tooltip
                  title={
                    inventory.some((item) => item.category === cat)
                      ? "Category has items"
                      : "Delete category"
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <TextField
            margin="normal"
            label="New Category"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            onKeyDown={handleAddCategory}
            helperText="Press Enter to add a new category"
          />
        )}

        <TextField
          margin="normal"
          label="Amount Ordered"
          type="number"
          fullWidth
          value={amountOrdered}
          onChange={(e) => setAmountOrdered(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Current Amount"
          type="number"
          fullWidth
          value={currentAmount}
          disabled // Current amount is non-editable
        />

        <TextField
          margin="normal"
          label="Price"
          type="number"
          fullWidth
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEdit} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryDialog;
