import React from "react";
import { Pie } from "react-chartjs-2";
import { Box, Typography } from "@mui/material"; 

const PieChart = ({ title, data, options }) => {
  return (
    <Box sx={{ marginTop: 6 }}>
      <Typography variant="h5" align="center" gutterBottom>
        {title}
      </Typography>
      <Pie data={data} options={options} />
    </Box>
  );
};

export default PieChart;
