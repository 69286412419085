import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme/theme";
import AppRoutes from "./routes";
import { openDB } from "./demo/utilities/indexedDB";

const App = () => {
  useEffect(() => {
    openDB()
      .then(() => {
        console.log("IndexedDB initialized");
      })
      .catch((error) => {
        console.error("Failed to initialize IndexedDB", error);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
