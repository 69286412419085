import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Tabs, Tab, TextField } from "@mui/material";
import MenuItems from "./MenuItems";
import MenuArchive from "./MenuArchive";
import CreateMenuItemDialog from "./Dialogs/CreateMenuItemDialog";
import HandleArchiveMenuItem from "./Functions/HandleArchiveMenuItem";
import HandleDeleteMenuItem from "./Functions/HandleDeleteMenuItem";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: "aes" });

const MenuCreation = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [menuItems, setMenuItems] = useState([]);
  const [archivedItems, setArchivedItems] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    const storedMenuData = ls.get("localDB_menuItems") || {
      menu: [],
      archived: [], // Retrieve archived items
      categories: [],
    };
    const storedMenuItems = storedMenuData.menu || [];
    const storedArchivedItems = storedMenuData.archived || []; // Get archived items
    const storedIngredients = ls.get("localDB_inventory")?.items || [];

    setMenuItems(storedMenuItems);
    setArchivedItems(storedArchivedItems); // Set archived items
    setIngredients(storedIngredients);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCreateItem = () => {
    setOpenCreateDialog(true);
  };

  const handleSaveNewItem = (newItem) => {
    setMenuItems([...menuItems, newItem]);
  };

  const openArchiveDialog = (item) => {
    setItemToArchive(item);
    setArchiveDialogOpen(true);
  };

  const handleUnarchive = (item) => {
    const updatedArchivedItems = archivedItems.filter(
      (archivedItem) => archivedItem.name !== item.name
    );
    setArchivedItems(updatedArchivedItems);
    setMenuItems([...menuItems, item]);

    const storedMenuData = ls.get("localDB_menuItems") || {};
    storedMenuData.menu = [...menuItems, item];
    storedMenuData.archived = updatedArchivedItems;
    ls.set("localDB_menuItems", storedMenuData);
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredArchivedItems = archivedItems.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)}
        sx={{ marginBottom: 2 }}
      >
        Back
      </Button>

      <Typography variant="h4" align="center" gutterBottom>
        Menu Creation
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Active" />
        <Tab label="Archive" />
      </Tabs>

      <Box sx={{ paddingTop: 3 }}>
        {tabValue === 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Search Menu"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateItem}
              >
                Create Item
              </Button>
            </Box>

            <MenuItems
              menuItems={filteredMenuItems}
              ingredients={ingredients}
              onArchive={openArchiveDialog}
            />
          </>
        )}

        {tabValue === 1 && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Search Archived Items"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>

            <MenuArchive
              archivedItems={filteredArchivedItems}
              ingredients={ingredients}
              onUnarchive={handleUnarchive}
              handleDeleteItem={(item) => {
                setItemToDelete(item);
                setDeleteDialogOpen(true);
              }}
            />
          </>
        )}
      </Box>

      <CreateMenuItemDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onSave={handleSaveNewItem}
      />

      {archiveDialogOpen && itemToArchive && (
        <HandleArchiveMenuItem
          item={itemToArchive}
          onClose={() => setArchiveDialogOpen(false)}
          onArchiveConfirm={() => {
            const updatedMenuItems = menuItems.filter(
              (menuItem) => menuItem.name !== itemToArchive.name
            );
            setMenuItems(updatedMenuItems);
            setArchivedItems([...archivedItems, itemToArchive]); // Add to archive

            const storedMenuData = ls.get("localDB_menuItems") || {};
            storedMenuData.menu = updatedMenuItems;
            storedMenuData.archived = [...archivedItems, itemToArchive];
            ls.set("localDB_menuItems", storedMenuData);

            setArchiveDialogOpen(false);
          }}
        />
      )}

      {deleteDialogOpen && itemToDelete && (
        <HandleDeleteMenuItem
          item={itemToDelete}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleteConfirm={() => {
            const updatedArchivedItems = archivedItems.filter(
              (archivedItem) => archivedItem.name !== itemToDelete.name
            );
            setArchivedItems(updatedArchivedItems);

            const storedMenuData = ls.get("localDB_menuItems") || {};
            storedMenuData.archived = updatedArchivedItems;
            ls.set("localDB_menuItems", storedMenuData);

            setDeleteDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default MenuCreation;
