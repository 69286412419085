// indexedDB.js
const DB_NAME = "AppDB";
const DB_VERSION = 1;
const STORE_NAME = "images";

export function openDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "id" });
      }
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      console.error("Error opening DB", event);
      reject("Failed to open DB");
    };
  });
}

export async function saveImage(id, imageFile) {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, "readwrite");
  const store = transaction.objectStore(STORE_NAME);

  const imageBlob = new Blob([imageFile], {
    type: imageFile.type || "image/png",
  });
  console.log(
    "Saving Blob with ID:",
    id,
    "Blob size:",
    imageBlob.size,
    "Type:",
    imageBlob.type
  );

  return new Promise((resolve, reject) => {
    const request = store.put({ id, imageBlob });
    request.onsuccess = () => {
      console.log("Image saved successfully with ID:", id);
      resolve();
    };
    request.onerror = (event) => {
      console.error("Error saving image", event);
      reject("Failed to save image");
    };
  });
}

export async function getImage(id) {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, "readonly");
  const store = transaction.objectStore(STORE_NAME);

  return new Promise((resolve, reject) => {
    const request = store.get(id);
    request.onsuccess = () => {
      const result = request.result?.imageBlob;
      if (result instanceof Blob) {
        console.log("Retrieved Blob:", result);
        resolve(result); 
      } else {
        console.log("No Blob found for ID:", id);
        resolve(null);
      }
    };
    request.onerror = (event) => {
      console.error("Error retrieving image", event);
      reject("Failed to retrieve image");
    };
  });
}

export async function deleteImage(id) {
  const db = await openDB();
  const transaction = db.transaction(STORE_NAME, "readwrite");
  const store = transaction.objectStore(STORE_NAME);
  return new Promise((resolve, reject) => {
    const request = store.delete(id);
    request.onsuccess = () => resolve();
    request.onerror = (event) => {
      console.error("Error deleting image", event);
      reject("Failed to delete image");
    };
  });
}
