import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const HandleArchiveMenuItem = ({ item, onClose, onArchiveConfirm }) => {
  const handleConfirmArchive = () => {
    const storedMenuData = ls.get("localDB_menuItems") || {
      menu: [],
      archived: [],
    };

    const updatedMenuItems = storedMenuData.menu.filter(
      (menuItem) => menuItem.name !== item.name
    );

    const updatedArchivedItems = [...(storedMenuData.archived || []), item];

    storedMenuData.menu = updatedMenuItems;
    storedMenuData.archived = updatedArchivedItems;
    ls.set("localDB_menuItems", storedMenuData);

    const newLog = {
      name: item.name,
      dateModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      action: "Archived",
    };
    storedMenuData.history = [...(storedMenuData.history || []), newLog];
    ls.set("localDB_menuItems", storedMenuData);

    onArchiveConfirm();
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Archive Menu Item</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to archive <strong>{item.name}</strong>? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirmArchive} color="primary">
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleArchiveMenuItem;
