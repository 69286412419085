import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const TransactionLogsTable = ({ transLogs }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Items</TableCell>
          <TableCell>Type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transLogs.map((log) => (
          <TableRow key={log.id || `log-${log.date}`}>
            <TableCell>{log.id || "N/A"}</TableCell>
            <TableCell>{log.date}</TableCell>
            <TableCell>{parseFloat(log.amount).toFixed(2)}</TableCell>
            <TableCell>{log.item}</TableCell>
            <TableCell>{log.type}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TransactionLogsTable;
