import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TableContainer,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import SecureLS from "secure-ls";
import InventoryHero from "./InventoryHero"; // Import the InventoryHero component

const ls = new SecureLS({ encodingType: "aes" });

const InventoryHistory = () => {
  const [history, setHistory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("dateModified");

  useEffect(() => {
    const storedInventory = ls.get("localDB_inventory") || { history: [] };
    setHistory(storedInventory.history || []);
  }, []);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortHistory = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="h5" gutterBottom>
        History
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === "action" ? order : false}>
                <TableSortLabel
                  active={orderBy === "action"}
                  direction={orderBy === "action" ? order : "asc"}
                  onClick={() => handleSort("action")}
                >
                  Action
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "name" ? order : false}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={() => handleSort("name")}
                >
                  Item
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "deliveryDate" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "deliveryDate"}
                  direction={orderBy === "deliveryDate" ? order : "asc"}
                  onClick={() => handleSort("deliveryDate")}
                >
                  Delivery Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "expiryDate" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "expiryDate"}
                  direction={orderBy === "expiryDate" ? order : "asc"}
                  onClick={() => handleSort("expiryDate")}
                >
                  Expiry Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Changes</TableCell>
              <TableCell
                sortDirection={orderBy === "dateModified" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "dateModified"}
                  direction={orderBy === "dateModified" ? order : "asc"}
                  onClick={() => handleSort("dateModified")}
                >
                  Date Modified
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortHistory(history, getComparator(order, orderBy)).map(
              (log, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      color:
                        log.action === "Added"
                          ? "green"
                          : log.action === "Deleted"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {log.action}
                  </TableCell>
                  <TableCell>{log.name}</TableCell>
                  <TableCell>{log.deliveryDate}</TableCell>
                  <TableCell>{log.expiryDate}</TableCell>
                  <TableCell>{log.changes || "N/A"}</TableCell>
                  <TableCell>{log.dateModified}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InventoryHistory;
