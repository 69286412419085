import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import dayjs from "dayjs";

import { Box, Typography, Button, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import PieChart from "./chartstables/PieChart";
import BarChart from "./chartstables/BarChart";
import TransactionLogsTable from "./chartstables/TransactionLogsTable";
import TotalSummary from "./chartstables/TotalSummary";
import {
  salesData,
  pieData,
  barOptions,
  pieOptions,
  transactionLogs,
} from "./SalesData";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ls = new SecureLS({ encodingType: "aes" });

const Sales = () => {
  const navigate = useNavigate();
  const [expenseData, setExpenseData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [logs, setLogs] = useState(transactionLogs);
  const [transLogs, setTransLogs] = useState([]);
  const [weeklyChartData, setWeeklyChartData] = useState(salesData);
  const [expenseChartData, setExpenseChartData] = useState(pieData);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);
  const [viewType, setViewType] = useState("weekly");

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
  };

  const handleViewTypeChange = (event) => {
    const value = event.target.value;
    setViewType(value);

    if (value === "daily") {
      setDateRange([dayjs(), dayjs()]);
    } else if (value === "weekly") {
      setDateRange([dayjs().subtract(7, "day"), dayjs()]);
    } else if (value === "monthly") {
      setDateRange([dayjs().startOf("month"), dayjs().endOf("month")]);
    }
  };

  useEffect(() => {
    const localExpense = ls.get("localDB_expense")?.expenses || [];
    const localRevenue = ls.get("localDB_revenue") || [];
    console.log("localExpense", localExpense);

    let combinedLogs = [];

    if (localExpense.length > 0) {
      setExpenseData(localExpense);

      const updatedLogs = localExpense.map((expense, index) => ({
        id: expense.id || "N/A",
        date: expense.date,
        amount: `-${expense.price}`,
        item: expense.name,
        category: expense.category,
        type: "Expense",
      }));
      combinedLogs = [...combinedLogs, ...updatedLogs];
    }

    if (localRevenue.length > 0) {
      setRevenueData(localRevenue);

      const revenueLogs = localRevenue.map((revenue, index) => ({
        id: localRevenue.length + index + 1,
        date: revenue.date,
        amount: revenue.amount,
        item: revenue.source,
        type: "Revenue",
      }));
      combinedLogs = [...combinedLogs, ...revenueLogs];
    }

    combinedLogs = combinedLogs.filter((log) => {
      const logDate = dayjs(log.date);
      return logDate.isAfter(dateRange[0]) && logDate.isBefore(dateRange[1]);
    });

    combinedLogs.sort((a, b) => new Date(a.date) - new Date(b.date));
    setLogs(combinedLogs);
    setTransLogs([...combinedLogs].reverse());

    // Group expenses by date
    const groupedExpenses = combinedLogs
      .filter((log) => log.type === "Expense")
      .reduce((acc, log) => {
        const date = dayjs(log.date).format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += Math.abs(parseFloat(log.amount));
        return acc;
      }, {});

    // Group revenues by date
    const groupedRevenues = combinedLogs
      .filter((log) => log.type === "Revenue")
      .reduce((acc, log) => {
        const date = dayjs(log.date).format("YYYY-MM-DD");
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += parseFloat(log.amount);
        return acc;
      }, {});

    const uniqueDates = Array.from(
      new Set([
        ...Object.keys(groupedExpenses),
        ...Object.keys(groupedRevenues),
      ])
    ).sort();

    const weeklyExpenses = uniqueDates.map(
      (date) => groupedExpenses[date] || 0
    );
    const weeklyRevenues = uniqueDates.map(
      (date) => groupedRevenues[date] || 0
    );

    const updatedWeeklyChartData = {
      labels: uniqueDates,
      datasets: [
        {
          label: "Revenue",
          backgroundColor: "green",
          data: weeklyRevenues,
        },
        {
          label: "Expense",
          backgroundColor: "red",
          data: weeklyExpenses,
        },
      ],
    };

    setWeeklyChartData(updatedWeeklyChartData);

    const filteredExpenses = combinedLogs.filter(
      (log) => log.type === "Expense"
    );

    if (filteredExpenses.length > 0) {
      const categories = [
        ...new Set(filteredExpenses.map((exp) => exp.category)),
      ];
      const categoryExpenses = categories.map((cat) =>
        filteredExpenses
          .filter((exp) => exp.category === cat)
          .reduce((total, exp) => total + Math.abs(parseFloat(exp.amount)), 0)
      );

      const updatedExpenseChartData = {
        labels: categories,
        datasets: [
          {
            label: "Expenses by Category",
            data: categoryExpenses,
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
          },
        ],
      };
      setExpenseChartData(updatedExpenseChartData);
    } else {
      const emptyExpenseChartData = {
        labels: ["No Data for this Date"],
        datasets: [
          {
            label: "Expenses by Category",
            data: [0],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
          },
        ],
      };
      setExpenseChartData(emptyExpenseChartData);
    }
  }, [dateRange, viewType]);

  const totalRevenue = logs
    .filter((log) => log.type === "Revenue")
    .reduce((acc, log) => acc + parseFloat(log.amount), 0);

  const totalExpense = logs
    .filter((log) => log.type === "Expense")
    .reduce((acc, log) => acc + parseFloat(log.amount), 0);

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Sales Reports
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <Select
          value={viewType}
          onChange={handleViewTypeChange}
          sx={{ marginRight: 2, width: 150 }}
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </Select>

        {viewType === "custom" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={dateRange[0]}
              onChange={(newValue) =>
                handleDateRangeChange([newValue, dateRange[1]])
              }
              sx={{ marginRight: 2, width: 150 }}
            />
            <DatePicker
              label="End Date"
              value={dateRange[1]}
              onChange={(newValue) =>
                handleDateRangeChange([dateRange[0], newValue])
              }
              sx={{ width: 150 }}
            />
          </LocalizationProvider>
        )}
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <BarChart data={weeklyChartData} options={barOptions} />
      </Box>

      <Box sx={{ marginTop: 6 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Sales by Category (Static Data)
        </Typography>
        <PieChart data={pieData} options={pieOptions} />
      </Box>

      <Box sx={{ marginTop: 6 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Expense by Category
        </Typography>
        <PieChart data={expenseChartData} options={pieOptions} />
      </Box>

      <Box sx={{ marginTop: 6 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Transaction Logs
        </Typography>
        <TransactionLogsTable transLogs={transLogs} />
      </Box>

      <TotalSummary totalRevenue={totalRevenue} totalExpense={totalExpense} />
    </Box>
  );
};

export default Sales;
