import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Demo Routes
import Login from "./demo/pages/auth/Login";
import Dashboard from "./demo/pages/dashboard/Dashboard";
import Sales from "./demo/pages/sales/Sales";
import Inventory from "./demo/pages/Inventory/Inventory";
import InventoryArchive from "./demo/pages/Inventory/InventoryArchive";
import InventoryHistory from "./demo/pages/Inventory/InventoryHistory"; 
import MenuCreation from "./demo/pages/menucreation/MenuCreation";
import MenuArchive from "./demo/pages/menucreation/MenuArchive";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/demo" element={<Navigate to="/demo/login" />} />
      <Route path="/demo/login" element={<Login />} />
      <Route path="/demo/dashboard" element={<Dashboard />} />
      <Route path="/demo/sales" element={<Sales />} />
      <Route path="/demo/inventory" element={<Inventory />} />
      <Route
        path="/demo/inventory/archive"
        element={<InventoryArchive />}
      />{" "}
      <Route path="/demo/inventory/history" element={<InventoryHistory />} />{" "}
      <Route path="/demo/menucreation" element={<MenuCreation />} />
      <Route path="/demo/menucreation/archived" element={<MenuArchive />} />s
    </Routes>
  );
};

export default AppRoutes;
