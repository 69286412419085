import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import SecureLS from "secure-ls";
import dayjs from "dayjs";
import InventoryHero from "./InventoryHero"; // Importing InventoryHero

const ls = new SecureLS({ encodingType: "aes" });

const InventoryArchive = () => {
  const [archivedInventory, setArchivedInventory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("expiryDate");

  useEffect(() => {
    const storedArchive = ls.get("localDB_archivedInv") || { items: [] };
    setArchivedInventory(storedArchive.items);
  }, []);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortInventory = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "expiryDate") {
      const dateA = dayjs(a.expiryDate);
      const dateB = dayjs(b.expiryDate);
      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    } else if (b[orderBy] < a[orderBy]) {
      return -1;
    } else if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Typography variant="h5" gutterBottom>
        Archived Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === "name" ? order : false}>
                <TableSortLabel
                  active={orderBy === "name"}
                  direction={orderBy === "name" ? order : "asc"}
                  onClick={() => handleSort("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "amountOrdered" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "amountOrdered"}
                  direction={orderBy === "amountOrdered" ? order : "asc"}
                  onClick={() => handleSort("amountOrdered")}
                >
                  Amount Ordered
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "currentAmount" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "currentAmount"}
                  direction={orderBy === "currentAmount" ? order : "asc"}
                  onClick={() => handleSort("currentAmount")}
                >
                  Current Amount
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "price" ? order : false}>
                <TableSortLabel
                  active={orderBy === "price"}
                  direction={orderBy === "price" ? order : "asc"}
                  onClick={() => handleSort("price")}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "deliveryDate" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "deliveryDate"}
                  direction={orderBy === "deliveryDate" ? order : "asc"}
                  onClick={() => handleSort("deliveryDate")}
                >
                  Delivery Date
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "expiryDate" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "expiryDate"}
                  direction={orderBy === "expiryDate" ? order : "asc"}
                  onClick={() => handleSort("expiryDate")}
                >
                  Expiry Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortInventory(
              archivedInventory,
              getComparator(order, orderBy)
            ).map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.amountOrdered}</TableCell>
                <TableCell>{item.currentAmount}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>{item.deliveryDate}</TableCell>
                <TableCell>{item.expiryDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InventoryArchive;
