import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  IconButton,
} from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import { getImage } from "../../utilities/indexedDB";

const MenuArchive = ({
  archivedItems,
  ingredients,
  onUnarchive,
  handleDeleteItem,
}) => {
  const [archivedWithImages, setArchivedWithImages] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      const updatedArchivedItems = await Promise.all(
        archivedItems.map(async (item) => {
          const safeImageId = item.imageId.replace(/\s+/g, "_");
          const imageBlob = safeImageId ? await getImage(safeImageId) : null;
          const imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null;

          return { ...item, image: imageUrl };
        })
      );

      const sortedArchivedItems = updatedArchivedItems.sort((a, b) =>
        a.category.localeCompare(b.category)
      );

      setArchivedWithImages(sortedArchivedItems);

      return () => {
        updatedArchivedItems.forEach((item) => {
          if (item.image) {
            URL.revokeObjectURL(item.image);
          }
        });
      };
    };

    loadImages();
  }, [archivedItems]);

  const findIngredient = (ingredientName) => {
    return ingredients.find((ingredient) => ingredient.name === ingredientName);
  };

  // Group archived items by category
  const categorizedArchivedItems = archivedWithImages.reduce((acc, item) => {
    const category = item.category || "Uncategorized";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  return (
    <Box>
      {Object.keys(categorizedArchivedItems).map((category, categoryIndex) => (
        <Box key={categoryIndex} sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom>
            {category}
          </Typography>
          <Grid container spacing={3}>
            {categorizedArchivedItems[category].map((item, index) => {
              const hasMissingIngredients = item.ingredients.some(
                (ingredient) => !findIngredient(ingredient.name)
              );

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      backgroundColor: hasMissingIngredients
                        ? "#FFCCCB"
                        : "#ADD8E6",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.image ? (
                      <CardMedia
                        component="img"
                        image={item.image}
                        alt={item.name}
                        height="150"
                        sx={{ objectFit: "cover" }}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No Image Available
                      </Typography>
                    )}
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {item.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Ingredients:
                      </Typography>
                      <ul>
                        {item.ingredients.map((ingredient, idx) => (
                          <li key={idx}>
                            {ingredient.name} ({ingredient.quantity}x){" "}
                            {!findIngredient(ingredient.name) && (
                              <span style={{ color: "red" }}> (missing)</span>
                            )}
                          </li>
                        ))}
                      </ul>
                      <Typography variant="body1">
                        Price: PHP {item.price}
                      </Typography>
                    </CardContent>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "8px",
                      }}
                    >
                      <IconButton
                        onClick={() => onUnarchive(item)}
                        color="primary"
                        size="small"
                      >
                        <UnarchiveIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteItem(item)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default MenuArchive;
