import React from "react";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";

const BarChart = ({ data, options }) => {
  return (
    <Box sx={{ marginTop: 4 }}>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default BarChart;
